export const timeline = [
  {
    year: 2017,
    month: 'August',
    description: 'daisee Founded',
    copy: "daisee was founded in 2017 by technology entrepreneur Richard Kimber. Richard's vision was to create a world-leading AI software company offering significant value to clients by blending commercial best practice and data science to create intelligent products that learn and adapt.",
    id: 'richard',
    link: '/our-people/richard-kimber/',
  },
  {
    year: 2019,
    month: 'March',
    description: 'daisee Launches v1.0.0',
    copy: 'daisee launches to the international marketplace. Their software produces a world-first automated, programmable QA Scorecard™ after applying AI and Natural Language Processing (NLP) to analyse, categorise and deliver deep insight into customer voice interactions.',
    id: 'ui',
  },
  {
    year: 2019,
    month: 'April',
    description: 'First Customer',
    copy: "daisee's first customer joins utilising the world's first automated programmable QA Scorecard™",
    id: 'customerOne',
  },
  {
    year: 2019,
    month: 'July',
    description: 'Launch of the Automated Script Adherence feature',
    copy: 'Script Adherence measures elements of the call to ensure that the agent has stated required information within the call for Compliance and Regulatory protection of the customer.',
    id: 'scriptAdherence',
  },
  {
    year: 2019,
    month: 'September',
    description: 'Launch of the Team View feature',
    copy: 'Team View provides team leaders, coaches, and supervisors with an overview of their complete teams performance and activity on one dashboard. This enables them to spend significantly less time scoring interactions and more time delivering engaging, data-driven feedback to agents.',
    id: 'teamView',
  },
  {
    year: 2019,
    month: 'December',
    description: 'Launch of the Issue Remediation Workflow functionality',
    copy: 'Issue Remediation Workflow automatically triages low-scoring, high-risk calls for operational personnel to review within the Programmable Scorecard™',
    id: 'workflow',
  },
  {
    year: 2020,
    month: 'February',
    description: 'Dr. David Fussell Joins',
    copy: 'David holds a Doctor of Philosophy from the University of Sydney in the field of Theoretical Physics. During his career, he has been at the cutting edge of data science research, inovation, and development.',
    id: 'david',
    link: '/our-people/dr-david-fussell/',
  },
  {
    year: 2020,
    month: 'March',
    description: 'Listed on Genesys AppFoundry',
    copy: 'This partnership allows Genesys customers from all market segments to easily access daisee’s highly accurate and multi-award-winning solution.',
    id: 'genesys',
    link: '/blog/daisee-is-now-available-on-the-genesys-appfoundry/',
  },
  {
    year: 2020,
    month: 'June',
    description: 'Microsoft Co-sell Ready',
    copy: 'The partnership with Microsoft enables customers to easily access daisee through its availability through the Microsoft Azure Marketplace.',
    id: 'microsoft',
  },
  {
    year: 2020,
    month: 'August',
    description: 'Gartner Cool Vendor 2020',
    copy: 'daisee selected by Gartner as a Cool Vendor in AI for Customer Analytics.',
    id: 'gartner',
    link: '/blog/daisee-selected-by-gartner-as-a-cool-vendor-in-ai-for-customer-analytics/',
  },
  {
    year: 2020,
    month: 'September',
    description: 'HIPAA Compliant',
    copy: 'daisee has been audited and awarded compliant by The Compliance Group as Health Insurance Portability and Accountability Act (HIPAA)',
    id: 'hipaa',
    link: 'https://www.hipaajournal.com/daisee-confirmed-as-hipaa-compliant-by-compliancy-group/',
  },
  {
    year: 2021,
    month: 'May',
    description: 'Launch of Essence',
    copy: 'Our flagship AI-powered feature, Essence, provides deep insight into the underlying reasons why customers are contacting your business. Essence actively analyses and recognises meaningful phrases used on each call to establish customer intent, signals, and trends.',
    link: '/ai/#essence',
    id: 'essence',
  },
  {
    year: 2021,
    month: 'July',
    description: 'Launch of Agent View',
    copy: 'Agent View expands the daisee platform allowing agents to view their own performance and activity data. This includes team comparison over time across the three key themes of communication, compliance, and conduct enabling agents the autonomy to reflect and review their activity.',
    id: 'agentView',
  },
  {
    year: 2022,
    month: 'January',
    description: 'daisee Completes SOC2, Type II Attestation',
    copy: `daisee achieves annual independent third party auditing for SOC2, Type II attestation. SOC2 defines criteria for managing customer data based on five “trust service principles” —security, availability, processing integrity, confidentiality and privacy. Type 11 attestation details operational effectiveness of those systems.`,
    id: 'soc2',
  },
  {
    year: 2023,
    month: 'January',
    description: 'daisee Completes GDPR Attestation',
    copy: `daisee becomes GDPR compliant, as assessed by an external and ongoing audit process. The General Data Protection Regulation (GDPR) is the toughest privacy and security law in the world. Though it was drafted and passed by the European Union (EU), it imposes obligations onto organizations anywhere, so long as they target or collect data related to people in the EU.`,
    id: 'gdpr',
  },
  {
    year: 2023,
    month: 'September',
    description: 'daisee Adds Chat to the Omnichannel QA Experience',
    copy: `daisee introduces a new and unique feature that now assesses chat conversations as well as voice conversations to assess the quality of all interactions between agents and customers.`,
    id: 'omnichannelChat',
  },
  {
    year: 2024,
    month: 'April',
    description: 'Kyle Bowness Appointed as COO/CFO',
    copy: `Kyle Bowness appointed as the company’s new Chief Financial Officer and Chief Operating Officer. Kyle started his career at daisee as Financial Accountant in 2019, and brings with him extensive experience in navigating the intricate landscapes of corporate governance, finance and strategic leadership.`,
    id: 'kyle',
    link: '/our-people/kyle-bowness/',
  },
]